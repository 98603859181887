import $ from "jquery";
const debounce = require('../jslib.js').debounce;
const {onModeEnter, onModeLeave, getMode} = require('../media.js');

export class DotNav {

    constructor(options) {
        this.options = $.extend({
            contentSections: $('.dot-nav--section'),
            navigationItems: $('.screen-smooth-scroll a'),
            animationDuration: 1000,
            headerOffset: $('.header--settings-block').height(),
            scrollDelay: 40,
            detectedTop: 100,

            updateLocationHash: false,
            usePageScroll: false
        }, options);

        this.delayedCompleteScroll = debounce(this.completeScroll, this.options.scrollDelay);

        this.resetScrollParams();

        // DEBUG mark first section as "topmost" to scroll to top (supposed this attribute to be set in html)
        this.options.contentSections.eq(0).data('topmost', true);

        this.updateNavigation();

        $(window).on('resize', () => {
            this.resetScrollParams();
        });

        document.addEventListener('scroll', () => {
            this.updateNavigation();

            if (!this.autoScrolling && this.pageScrollEnabled) {
                this.delayedCompleteScroll();
            }
        }, {passive: true});

        this.options.navigationItems.on('click', (event) => {
            event.preventDefault();
            this.smoothScroll($(event.currentTarget.hash));
        });

        if (this.options.usePageScroll) {
            // let page scroll work only in desktop mode
            this.pageScrollEnabled = (getMode() != 'mob');
            onModeEnter('desktop', () => {
                this.pageScrollEnabled = true;
            });
            onModeLeave('desktop', () => {
                this.pageScrollEnabled = false;
            });
        } else {
            this.pageScrollEnabled = false;
        }
    }

    updateNavigation () {
        const _this = this;
        const options = this.options;

        options.contentSections.each(function () {
            const $this = $(this);
            let id = $this.attr('id');

            let activeItem = $(`.screen-smooth-scroll a[href="#${id}"]`);

            /** distance from page top, where active section is detected */
            let detectedTop = options.detectedTop; //$(window).height()/2;

            if (
                ($this.offset().top < $(window).scrollTop() + detectedTop)
                && ($this.offset().top + $this.height() >= $(window).scrollTop() + detectedTop)
            ) {
                $this.addClass('js-scroll-current');
                activeItem.addClass('active');
                _this.updateLocationHash(id);
            } else if ($this.data('topmost') && $(window).scrollTop() < options.headerOffset) {
                $this.addClass('js-scroll-current');
                activeItem.addClass('active');
                _this.updateLocationHash(id);
            } else {
                $this.removeClass('js-scroll-current');
                activeItem.removeClass('active');
            }
        });
    }

    smoothScroll($target, direction) {
        if ($target.offset() === undefined) return;

        let top = $target.data('topmost') ? 0 : $target.offset().top - this.options.headerOffset;

        if (direction === 'up') {
            if (top > $(document).scrollTop()) {
                return;
            }
        } else if (direction === 'down') {
            if (top < $(document).scrollTop()) {
                return;
            }
        }

        this.autoScrolling = true;
        $('body, html').animate({
            'scrollTop': top
        }, this.options.animationDuration, () => {
            setTimeout(() => {
                this.autoScrolling = false;
            }, 200);
        });
    }

    completeScroll() {
        var scTop = $(document).scrollTop();

        if (this.lastScrool < scTop) {
            this.direction = 'down';
        } else {
            this.direction = 'up';
        }

        this.lastScrool = scTop;

        let currentSection = this.options.contentSections.filter('.js-scroll-current');
        if (!currentSection.length) return;

        if (this.direction === 'up') {
            this.smoothScroll(currentSection, 'up');
        } else if (this.direction === 'down') {
            let nextSection = currentSection.next();
            this.smoothScroll(nextSection, 'down');
        }
    }

    resetScrollParams() {
        this.direction = undefined;
        this.lastScrool = $(document).scrollTop();
    }

    updateLocationHash(id) {
        if (this.options.updateLocationHash) {
            if(history.pushState) {
                history.pushState(null, null, '#' + id);
            }
            else {
                location.hash = '#' + id;
            }
        }
    }

}
