/**
 * @module media
 * checking media (tablet, phone, desktop etc.)
 */

/**
 * check if in phone mode
 * @return {boolean}
 */
export function isMob() {
    let mobWidth = window.matchMedia("(max-width: 768px)");
    return mobWidth.matches;
}

/**
 * check if in tablet mode
 * @return {boolean}
 */
export function isTablet() {
    let tabletWidth = window.matchMedia("(max-width: 1023px)");
    return tabletWidth.matches;
}


// mode change listeners

export function getMode() {
    return isMob() ? 'mob' : (isTablet() ? 'tablet' : 'desktop');
}

var currentMode = getMode();
const modeEnterListeners = {
    'mob': [],
    'tablet': [],
    'desktop': []
};
const modeLeaveListeners = {
    'mob': [],
    'tablet': [],
    'desktop': []
};

window.addEventListener('resize', (event) => {
    let nextMode = getMode();

    if (nextMode != currentMode) {

        for (let callback of modeLeaveListeners[currentMode]) {
            callback();
        }
        for (let callback of modeEnterListeners[nextMode]) {
            callback();
        }

        currentMode = nextMode;
    }
});

export function onModeEnter(mode, callback) {
    modeEnterListeners[mode].push(callback);
}

export function onModeLeave(mode, callback) {
    modeLeaveListeners[mode].push(callback);
}
