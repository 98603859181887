const DotNav = require('../components/DotNav').DotNav;
const moreBookingsCarousel = require('../components/moreBookingsCarousel').moreBookingsCarousel;

new DotNav();
new moreBookingsCarousel();

var g_heightHeader;
g_heightHeader = $('.header .header--settings-block .header--settings-container').height();

$(window).resize(function () {
    g_heightHeader = $('.header .header--settings-block .header--settings-container').height();
    setHeightForCategoryItem();
});

function setHeightForCategoryItem() {
    var h = $(window).height() - ($('.categories-section .title--h2').outerHeight(true) + g_heightHeader);
    $('.categories-section .categories--row').css({'minHeight': h});
}

setHeightForCategoryItem();

//winter parallax for here screen
function winterParallax(){
    let $parallaxBlock = $('.parallax-winter');
    if($parallaxBlock.length){
        //console.log('winter parallax for here screen');

        let $snowLine_back = $('.parallax-winter__snow-wave--back');
        let $snowLine_front = $('.parallax-winter__snow-wave--front');
            lFollowX = 0,
            lFollowY = 0,

            lFollowX_2 = 0,
            lFollowY_2 = 0,

            x = 0,
            y = 0,

            x_2 = 0,
            y_2 = 0,

            friction = 1 / 30;
            friction_2 = 1 / 30;

        function moveBackground() {
            x += (lFollowX - x) * friction;
            y += (lFollowY - y) * friction;

            x_2 += (lFollowX_2 - x_2) * friction;
            y_2 += (lFollowY_2 - y_2) * friction;

            translate = 'translate(' + x + 'px, ' + y + 'px)';
            translate_2 = 'translate(' + (x_2) + 'px, ' + (y_2) + 'px)';

            $snowLine_back.css({
                '-webit-transform': translate,
                '-moz-transform': translate,
                'transform': translate
            });

            $snowLine_front.css({
                '-webit-transform': translate_2,
                '-moz-transform': translate_2,
                'transform': translate_2
            });

            window.requestAnimationFrame(moveBackground);
        }

        $('.main-section.main-top-section').on('mousemove click', function(e) {

            let lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
            let lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));

            let lMouseX_2 = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
            let lMouseY_2 = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));

            lFollowX = (20 * lMouseX) / 180; // 100 : 12 = lMouxeX : lFollow
            lFollowY = (20 * lMouseY) / 270;

            lFollowX_2 = (20 * lMouseX_2) / 25;
            lFollowY_2 = (20 * lMouseY_2) / 90;

        });

        moveBackground();
    }
}

//winterParallax();

window.onload = function(){
    let $parallaxBlock = $('.parallax-winter');
    if($parallaxBlock.length){

        //canvas init
        var canvas = document.getElementById("canvas");
        var ctx = canvas.getContext("2d");

        //canvas dimensions
        var W = window.innerWidth;
        var H = window.innerHeight;
        canvas.width = W;
        canvas.height = H;

        //snowflake particles
        var mp = 190; //max particles
        var particles = [];
        for(var i = 0; i < mp; i++)
        {
            particles.push({
                x: Math.random()*W, //x-coordinate
                y: Math.random()*H, //y-coordinate
                r: Math.random()*4+1, //radius
                d: Math.random()*mp //density
            })
        }

        //Lets draw the flakes
        function draw()
        {
            ctx.clearRect(0, 0, W, H);

            ctx.fillStyle = "#f1f6ff";
            ctx.beginPath();
            for(var i = 0; i < mp; i++)
            {
                var p = particles[i];
                ctx.moveTo(p.x, p.y);
                ctx.arc(p.x, p.y, p.r, 0, Math.PI*2, true);
            }
            ctx.fill();
            update();
        }

        //Function to move the snowflakes
        //angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
        var angle = 0;
        function update()
        {
            angle += 0.01;
            for(var i = 0; i < mp; i++)
            {
                var p = particles[i];
                //Updating X and Y coordinates
                //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
                //Every particle has its own density which can be used to make the downward movement different for each flake
                //Lets make it more random by adding in the radius
                p.y += Math.cos(angle+p.d) + 1 + p.r/2;
                p.x += Math.sin(angle) * 2;

                //Sending flakes back from the top when it exits
                //Lets make it a bit more organic and let flakes enter from the left and right also.
                if(p.x > W+5 || p.x < -5 || p.y > H)
                {
                    if(i%3 > 0) //66.67% of the flakes
                    {
                        particles[i] = {x: Math.random()*W, y: -10, r: p.r, d: p.d};
                    }
                    else
                    {
                        //If the flake is exitting from the right
                        if(Math.sin(angle) > 0)
                        {
                            //Enter from the left
                            particles[i] = {x: -5, y: Math.random()*H, r: p.r, d: p.d};
                        }
                        else
                        {
                            //Enter from the right
                            particles[i] = {x: W+5, y: Math.random()*H, r: p.r, d: p.d};
                        }
                    }
                }
            }
        }

        //animation loop
        setInterval(draw, 45);
    }
}
