// IMPORTANT! We should not add slick carousel here, because it is already added in app.js.
// Also do not require jquery, because it is already injected by webpack
//const $ = require('jquery');
//require('slick-carousel');
//require('intersection-observer');

export class moreBookingsCarousel {

    constructor(options) {
        this.options = $.extend({
            node: $('.book-sl--main')
        }, options);

        var instance =this;
        var observer = new IntersectionObserver(function (data) {
            $(data).each(function () {
                if (this.isIntersecting) {
                    observer.unobserve(this.target);
                    instance.initCarousel();
                }
            });
        });
        observer.POLL_INTERVAL = 100;
        observer.USE_MUTATION_OBSERVER = true;

        $('#more-bookings').each(function () {
            observer.observe(this);
        });
    }

    initCarousel () {
        let mainBookCarousel = $('.book-sl--main').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnFocus: true,
            pauseOnHover: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.book-sl--nav'
        });
        let navMainBookCarousel = $('.book-sl--nav').slick({
            pauseOnFocus: true,
            pauseOnHover: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            asNavFor: '.book-sl--main',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerPadding: '60px',
                        slidesToShow: 3,
                        variableWidth: true
                    }
                }
            ]
        });

        function slickPause() {
            mainBookCarousel.slick('slickPause');
        }

        navMainBookCarousel.mouseover(function () {
            slickPause();
            //console.log(1)
        });

        navMainBookCarousel.mouseout(function () {
            mainBookCarousel.slick('slickPlay')
            //console.log(0)
        });
    }

}


